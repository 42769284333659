import styled from '@emotion/styled';
import { css } from '@emotion/core';
import SearchBox from './search-box';
import { PropType } from '../../gatsby-plugin-theme-ui';

const open = (p: PropType) => css`
  background: ${p.theme.colors.backgroundAccent};
  cursor: text;
`;

const closed = css`
  width: 0;
  background: transparent;
  cursor: pointer;
`;

const shadowClosed = css`
  box-shadow: rgb(0 0 0 / 4%) 0px 3px 8px 0px, rgb(0 0 0 / 2%) 0px 2px 0px -1px;
`;

export default styled(SearchBox)<PropType & { hasFocus: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${p => p.theme.sizing.borderRadiusHeavy};
  margin-bottom: ${p => p.theme.sizing.spacing16};
  padding: ${p => p.theme.sizing.spacing4};
  border-radius: ${p => p.theme.sizing.borderRadiusHeavy};
  background-color: ${p => p.theme.colors.backgroundAccent};
  transition: all 0.25s;
  border: 1px solid ${p => p.theme.colors.horizontalRule};
  color: ${p => p.theme.colors.primary};
  &:hover {
    opacity: 1;
    box-shadow: none;
  }
  &:hover .SearchIcon {
    color: ${p => p.theme.colors.primary};
  }
  ${({ hasFocus }) => (hasFocus ? `opacity: 1;` : `opacity: 1;`)}
  ${({ hasFocus }) => (hasFocus ? `` : shadowClosed)}

  .SearchInput {
    outline: none;
    border: none;
    font-size: ${p => p.theme.sizing.fontSize2};
    transition: all 100ms;
    border-radius: ${p => p.theme.sizing.borderRadiusLight};
    margin-right: ${p => p.theme.sizing.spacing4};
    flex: 1 0 auto;
    color: ${p => p.theme.colors.grey};
    background-color: ${p => p.theme.colors.backgroundAccent};
    ::placeholder {
      color: ${p => p.theme.colors.grey};
    }
    ${({ hasFocus }) => (hasFocus ? open : closed)}
  }

  .SearchIcon {
    width: ${p => p.theme.sizing.spacing4};
    margin: ${p => p.theme.sizing.spacing2};
    color: ${p => p.theme.colors.primary};
    pointer-events: none;
    transition: all 0.25s;
  }
`;
