import algoliasearch from 'algoliasearch/lite';
import { createRef, useState } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import StyledSearchBox from './styled-search-box';
import StyledSearchResult from './styled-search-result';
import StyledSearchRoot from './styled-search-root';
import useClickOutside from './use-click-outside';
import styled from '@emotion/styled';

export default function Search({ indices }) {
  const rootRef = createRef<any>();
  const [query, setQuery] = useState<string>();
  const [hasFocus, setFocus] = useState(false);
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_API_KEY,
  );

  useClickOutside(rootRef, () => setFocus(false));

  return (
    <RootContainer>
      <StyledSearchRoot ref={rootRef}>
        <InstantSearch
          searchClient={searchClient}
          indexName={indices[0].name}
          onSearchStateChange={({ query }) => setQuery(query)}
        >
          <StyledSearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} />
          <StyledSearchResult
            show={query && query.length > 0 && hasFocus}
            indices={indices}
          />
        </InstantSearch>
      </StyledSearchRoot>
    </RootContainer>
  );
}

const RootContainer = styled.div`
  margin: 0 auto;
  max-width: 720px;
`;
