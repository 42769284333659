import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { Search as SearchIcon } from '@styled-icons/fa-solid';
import { connectPoweredBy } from 'react-instantsearch-dom';
import styled from '@emotion/styled';
import { PropType } from '../../gatsby-plugin-theme-ui';

const A = styled.a<PropType>`
  font-size: ${p => p.theme.sizing.fontSize0};
  color: ${p => p.theme.colors.grey};
  text-decoration: none;
  opacity: 0.5;
`;

const PoweredBy = ({ url }) => (
  <A style={{}} href={url}>
    قدرت گرفته از Algolia
  </A>
);

const CustomPoweredBy = connectPoweredBy(PoweredBy);

export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus }) => (
    <form className={className}>
      <SearchIcon size={16} className="SearchIcon" />
      <input
        className="SearchInput"
        type="text"
        placeholder="جست‌وجو"
        aria-label="Search"
        onChange={e => refine(e.target.value)}
        value={currentRefinement}
        onFocus={onFocus}
      />
      <CustomPoweredBy />
    </form>
  ),
);
