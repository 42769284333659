import styled from '@emotion/styled';
import { css } from '@emotion/core';
import SearchResult from './search-result';
import { PropType } from '../../gatsby-plugin-theme-ui';

const Popover = (p: PropType) => css`
  max-height: 80vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 100%;
  margin-top: 0.5em;
  width: 100%;
  box-shadow: ${p.theme.shadows.default};
  color: ${p.theme.colors.grey};
  padding: ${p.theme.sizing.spacing4};
  border-radius: ${p.theme.sizing.borderRadiusLight};
  background: ${p.theme.colors.backgroundAccent};
  overflow-y: auto;
  overflow-x: hidden;
`;
export default styled(SearchResult)<PropType & { show: boolean }>`
  display: ${p => (p.show ? `block` : `none`)};
  ${Popover}
  .HitCount {
    display: flex;
    justify-content: flex-start;
    margin-bottom: ${p => p.theme.sizing.spacing6};
    padding-bottom: ${p => p.theme.sizing.spacing6};
    border-bottom: 2px solid ${p => p.theme.colors.horizontalRule};
  }
  .Hits {
    ul {
      list-style: none;
      margin-left: 0;
    }
    li.ais-Hits-item {
      margin-bottom: ${p => p.theme.sizing.spacing4};
      a {
        color: ${p => p.theme.colors.primary};
        font-size: ${p => p.theme.sizing.fontSize2};
        h5 {
          margin-bottom: ${p => p.theme.sizing.spacing2};
        }
        span.snippet {
          font-size: ${p => p.theme.sizing.fontSize1};
          color: ${p => p.theme.colors.grey};
        }
      }
    }
  }
  .ais-PoweredBy {
    display: flex;
    justify-content: flex-end;
    font-size: 80%;
    svg {
      width: 60px;
    }
  }
`;
