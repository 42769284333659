import React from 'react';
import { Link } from 'gatsby';
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
} from 'react-instantsearch-dom';

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits;

  return hitCount > 0 ? (
    <div className="HitCount">
      {hitCount.toLocaleString('fa-IR')} نتیجه تو{' '}
      {searchResults.processingTimeMS.toLocaleString('fa-IR')} میلی‌ثانیه پیدا
      شد.
    </div>
  ) : null;
});

const PageHit = ({ hit }) => (
  <div>
    <Link to={hit.slug}>
      <h5>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </h5>
      <span className="snippet">
        <Snippet attribute="excerpt" hit={hit} tagName="mark" />
        ...
      </span>
    </Link>
  </div>
);

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <Hits className="Hits" hitComponent={PageHit} />
  </Index>
);

const SearchResult = ({ indices, className }) => (
  <div className={className}>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
);

export default SearchResult;
