import React from 'react';
import styled from '@emotion/styled';

import Image from '@components/Image';
import { IAuthor } from '@types';
import theme from '../../gatsby-plugin-theme-ui';

type PropType = {
  theme: typeof theme;
};

const SocialIcon = ({ text, icon, href }) => {
  return (
    <SocialIconContainer
      className="social-icon"
      target="_blank"
      rel="noreferrer"
      href={href}
    >
      <img alt={text} src={icon} /> {text}
    </SocialIconContainer>
  );
};

const Bio: React.FC<IAuthor> = ({ author }) => {
  return (
    <BioContainer>
      <BioAvatar as={'div'} data-a11y="false" aria-label="Author's bio">
        <BioAvatarInner>
          <RoundedImage src={author.avatar.medium} />
        </BioAvatarInner>
      </BioAvatar>
      <Container>
        <BioText dangerouslySetInnerHTML={{ __html: author.bio }} />
        <BioText>
          {`من رو میتونید تو `}
          <strong>
            <SocialIcon
              href={'https://www.instagram.com/aientech/'}
              icon="https://icongr.am/entypo/instagram.svg?size=16&color=f3b533"
              text="اینستاگرام"
            />
          </strong>
          {`، `}
          <strong>
            <SocialIcon
              href={'https://github.com/AienTech'}
              icon="https://icongr.am/entypo/github.svg?size=16&color=f3b533"
              text="گیت‌هاب"
            />
          </strong>
          {`و `}
          <strong>
            <SocialIcon
              href={'https://twitter.com/aientech'}
              icon="https://icongr.am/entypo/twitter.svg?size=16&color=f3b533"
              text="توییتر"
            />
          </strong>
          {` پیدا کنید و یا خیلی ساده `}
          <strong>
            <SocialIcon
              href={'https://you.aien.me/join'}
              icon="https://icongr.am/entypo/mail.svg?size=16&color=f3b533"
              text="عضو خبرنامه‌ی من بشید"
            />
          </strong>
          .
        </BioText>
      </Container>
    </BioContainer>
  );
};

export default Bio;

const BioContainer = styled.div<PropType>`
  display: flex;
  align-items: center;
  position: relative;
  ${theme.isRtl ? 'right' : 'left'}: -${theme.sizing.spacing20};
`;

const Container = styled.div<PropType>`
  display: flex-column;
  p {
    margin-bottom: ${theme.sizing.spacing5};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const BioAvatar = styled.div<PropType>`
  display: block;
  position: relative;
  height: ${theme.sizing.heightXXS};
  width: ${theme.sizing.heightXXS};
  border-radius: ${theme.sizing.borderRadiusHalf};
  background: rgba(0, 0, 0, 0.25);
  margin-${theme.isRtl ? 'left' : 'right'}: ${theme.sizing.spacing32};
  margin: ${theme.sizing.spacing10} ${
  theme.isRtl ? theme.sizing.spacing10 : theme.sizing.spacing16
} ${theme.sizing.spacing10} ${
  theme.isRtl ? theme.sizing.spacing16 : theme.sizing.spacing10
};
`;

const RoundedImage = styled(Image)`
  border-radius: ${theme.sizing.borderRadiusHalf};
`;

const BioAvatarInner = styled.div<PropType>`
  height: ${theme.sizing.heightXXS};
  width: ${theme.sizing.heightXXS};
  border-radius: ${theme.sizing.borderRadiusHalf};
  background: rgba(0, 0, 0, 0.25);
  margin-${theme.isRtl ? 'left' : 'right'}: ${theme.sizing.spacing32};
  overflow: hidden;
`;

const BioText = styled.p<PropType>`
  max-width: ${theme.sizing.maxWidthLG};
  font-size: ${theme.sizing.fontSize1};
  line-height: ${theme.sizing.lineHeightNormal};
  color: ${p => p.theme.colors.grey};

  a {
    color: ${p => p.theme.colors.primary};
    text-decoration: none;
  }
`;

const SocialIconContainer = styled.a<PropType>`
  display: inline-block;
  margin: 0 ${theme.sizing.spacing2};
  text-decoration: none;
  padding-bottom: ${theme.sizing.spacing2};
  cursor: pointer;
  &:hover {
    border-bottom: 2px solid ${p => p.theme.colors.horizontalRule};
    padding-bottom: 0;
  }
  img {
    vertical-align: middle;
    margin-left: ${theme.sizing.spacing1};
  }
`;
